// ==============================|| OVERRIDES - ALERT TITLE ||============================== //

export default function AccordionSummary() {
  return {
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          background: "transparent",
        },
      },
    },
  };
}

// ==============================|| PRESET THEME - THEME SELECTOR ||============================== //

const Theme = () => {
  // Added by David
  const custom = {
    blue: {
      main: "#032142",
      opacity_80: "#032142CC",
      opacity_60: "#03214299",
    },
    yellow: {
      main: "#FDCF6F",
      opacity_80: "#FDCF6FCC",
      opacity_50: "#FDCF6F80",
      lighter: "#f7e1b2", // same with opacity_50 but without alpha channel
    },
    grey: {
      black: "#202020",
      dark: "#515151",
      medium: "#747474",
      light: "#C1C1C1",
      lines: "#E9E9E9",
    },
    white: {
      main: "#FFFFFF",
      bglight: "#F0F2F5",
      lightblue: "#E3EAEF",
    },
    red: {
      main: "#DE0000D9",
      opacity_50: "#DE000080",
      opacity_10: "#DE00001A",
    },
    green: {
      main: "#017306D9",
      opacity_20: "#01730633",
    },
    extra: {
      blue: "#367CFF",
      orange: "#FD6020",
    },
  };

  const primary = {
    25: "#FBFDFD",
    50: "#F3FAF8",
    100: "#EBF7F4",
    200: "#E3F3F0",
    lighter: custom.blue.opacity_60,
    300: "#D7EEE9",
    400: "#D2ECE7",
    light: custom.blue.opacity_80,
    500: "#CCEAE5",
    main: custom.blue.main,
    600: "#C7E7E2",
    dark: "#C7E7E2",
    700: "#B0DFD4",
    800: "#9DD7CA",
    900: "#8AD0C0",
    darker: "#8AD0C0",
    text: "white",
    contrast: custom.yellow.main,
    contrastText: custom.grey.dark,
  };
  const darkgreen = {
    25: "#DEF0EC",
    50: "#BDE1D8",
    100: "#9BD1C5",
    200: "#7AC2B1",
    light: "#7AC2B1",
    300: "#59B39E",
    main: "#59B39E",
    400: "#50A18E",
    dark: "#50A18E",
    500: "#478F7E",
    600: "#3E7D6F",
    700: "#356B5F",
    800: "#2C594F",
    900: "#24483F",
    contrastText: "#FFFFFF",
  };
  const darkteal = {
    25: "#A4DBE0",
    50: "#96CDD3",
    100: "#89C2C7",
    200: "#7CB8BD",
    300: "#70AFB3",
    light: "#70AFB3",
    400: "#63A6AA",
    main: "#63A6AA",
    500: "#569EA1",
    dark: "#569EA1",
    600: "#489699",
    700: "#388E90",
    800: "#248789",
    900: "#008082",
    contrastText: "#FFFFFF",
  };
  const orange = {
    25: "#FFF1E8",
    50: "#FFC7A3",
    100: "#FFAA76",
    200: "#FF8E48",
    300: "#FF8031",
    light: "#FF8031",
    400: "#FF721A",
    main: "#FF721A",
    500: "#E56717",
    dark: "#E56717",
    600: "#CC5B15",
    700: "#0F6C56",
    800: "#0A483A",
    900: "#05241D",
    contrastText: "#FFFFFF",
  };
  const green = {
    25: "#F6FBFB",
    50: "#DAEFED",
    100: "#C7E8E5",
    200: "#B5E0DC",
    300: "#ABDCD7",
    light: "#ABDCD7",
    400: "#A2D8D3",
    // main: "#A2D8D3",
    500: "#92C2BE",
    dark: "#92C2BE",
    600: "#82ADA9",
    700: "#719794",
    800: "#61827F",
    900: "#516C6A",
    contrastText: "#101828",
    main: "rgba(1, 115, 6, 0.85)",
    opacity_20: "rgba(1, 115, 6, 0.2)",
    opacity_85: "rgba(1, 115, 6, 0.85)",
  };
  const brown = {
    25: "#F5EDE5",
    50: "#D6B899",
    100: "#C29466",
    200: "#AD7133",
    300: "#A35F19",
    light: "#A35F19",
    400: "#994D00",
    main: "#994D00",
    500: "#8A4500",
    dark: "#8A4500",
    600: "#7A3E00",
    700: "#6B3600",
    800: "#5C2E00",
    900: "#4D2600",
    contrastText: "#FFFFFF",
  };
  const darkblue = {
    25: "#F1F6F9",
    50: "#C9DBE8",
    100: "#ADCADD",
    200: "#92B8D1",
    300: "#85AFCC",
    light: "#85AFCC",
    400: "#77A6C6",
    main: "#77A6C6",
    500: "#6B95B2",
    dark: "#6B95B2",
    600: "#5F859E",
    700: "#53748B",
    800: "#476477",
    900: "#3B5363",
    contrastText: "#FFFFFF",
  };
  const grey = {
    25: "#FCFCFD",
    50: "#FAFAFC",
    100: "#F2F4F7",
    200: "#E4E7EC",
    300: "#D0D5DD",
    // lighter: "#D0D5DD",
    400: "#98A2B3",
    // light: "#98A2B3",
    500: "#667085",
    // main: "#667085",
    600: "#475467",
    // dark: "#475467",
    700: "#344054",
    // darker: "#344054",
    800: "#344054",
    900: "#101828",
    contrastText: "#FFFFFF",
    darker: "#202020",
    dark: "#515151",
    main: "#747474",
    light: "#C1C1C1",
    lighter: "#E9E9E9",
  };
  const error = {
    25: "#FFFBFA",
    50: "#FEF3F2",
    100: "#FEE4E2",
    200: "#FECDCA",
    300: "#FDA29B",
    400: "#F97066",
    light: "#F97066",
    500: "#F04438",
    main: "#F04438",
    600: "#D92D20",
    dark: "#D92D20",
    700: "#B42318",
    800: "#912018",
    900: "#7A271A",
    contrastText: "#FFFFFF",
  };
  const warning = {
    25: "#FFFBF0",
    50: "#FFF8E5",
    100: "#FFF1CC",
    200: "#FFEBB2",
    300: "#FFE499",
    400: "#FFDD80",
    500: "#FFDD80",
    600: "#FFCF4C",
    700: "#FFC933",
    light: "#FFC933",
    800: "#FFC219",
    main: custom.white.lightblue,
    900: "#FFBE0D",
    dark: "#FFBE0D",
    text: custom.grey.dark,
    contrast: custom.white.lightblue,
    contrastText: custom.blue.main,
  };
  const info = {
    25: "#E5F4FC",
    50: "#99D1F1",
    100: "#66BBEB",
    200: "#33A4E4",
    light: "#33A4E4",
    300: "#1998E0",
    main: "#1998E0",
    400: "#008DDD",
    dark: "#008DDD",
    500: "#007FC7",
    600: "#0071B1",
    700: "#00639B",
    800: "#005585",
    900: "#00476F",
    contrastText: "#FFFFFF",
  };
  const success = {
    25: "#F6FEF9",
    50: "#ECFDF3",
    100: "#D1FADF",
    200: "#A6F4C5",
    300: "#6CE9A6",
    400: "#32D583",
    light: custom.yellow.opacity_80,
    500: "#12B76A",
    main: custom.yellow.main,
    600: "#039855",
    dark: "#039855",
    700: "#027A48",
    800: "#05603A",
    900: "#054F31",
    text: custom.grey.dark,
    contrast: custom.blue.main,
    contrastText: "white",
  };

  const tab = {
    25: "#FBFDFD",
    50: "#F3FAF8",
    100: "#EBF7F4",
    200: "#E3F3F0",
    lighter: custom.blue.opacity_60,
    300: "#D7EEE9",
    400: "#D2ECE7",
    light: custom.yellow.opacity_50,
    500: "#CCEAE5",
    main: custom.yellow.opacity_80,
    600: "#C7E7E2",
    dark: "#C7E7E2",
    700: "#B0DFD4",
    800: "#9DD7CA",
    900: "#8AD0C0",
    darker: "#8AD0C0",
    text: custom.grey.dark,
    contrast: custom.yellow.opacity_50,
    contrastText: custom.grey.dark,
  };

  return {
    primary,
    darkgreen,
    secondary: grey,
    darkteal,
    orange,
    green,
    brown,
    darkblue,
    grey,
    error,
    warning,
    info,
    success,
    tab,
    custom,
  };
};

export default Theme;

// ==============================|| OVERRIDES - TABLE CELL ||============================== //

export default function TableFooter() {
  return {
    MuiTableFooter: {
      styleOverrides: {
        root: {
          backgroundColor: "#FFFFFF",
        },
      },
    },
  };
}

const CountryData = {
  AD: { x: 42.546245, y: 1.601554, country: "Andorra" },
  AE: { x: 23.424076, y: 53.847818, country: "United Arab Emirates" },
  AF: { x: 33.93911, y: 67.709953, country: "Afghanistan" },
  AG: { x: 17.060816, y: -61.796428, country: "Antigua and Barbuda" },
  AI: { x: 18.220554, y: -63.068615, country: "Anguilla" },
  AL: { x: 41.153332, y: 20.168331, country: "Albania" },
  AM: { x: 40.069099, y: 45.038189, country: "Armenia" },
  AN: { x: 12.226079, y: -69.060087, country: "Netherlands Antilles" },
  AO: { x: -11.202692, y: 17.873887, country: "Angola" },
  AQ: { x: -75.250973, y: -0.071389, country: "Antarctica" },
  AR: { x: -38.416097, y: -63.616672, country: "Argentina" },
  AS: { x: -14.270972, y: -170.132217, country: "American Samoa" },
  AT: { x: 47.516231, y: 14.550072, country: "Austria" },
  AU: { x: -25.274398, y: 133.775136, country: "Australia" },
  AW: { x: 12.52111, y: -69.968338, country: "Aruba" },
  AZ: { x: 40.143105, y: 47.576927, country: "Azerbaijan" },
  BA: { x: 43.915886, y: 17.679076, country: "Bosnia and Herzegovina" },
  BB: { x: 13.193887, y: -59.543198, country: "Barbados" },
  BD: { x: 23.684994, y: 90.356331, country: "Bangladesh" },
  BE: { x: 50.503887, y: 4.469936, country: "Belgium" },
  BF: { x: 12.238333, y: -1.561593, country: "Burkina Faso" },
  BG: { x: 42.733883, y: 25.48583, country: "Bulgaria" },
  BH: { x: 25.930414, y: 50.637772, country: "Bahrain" },
  BI: { x: -3.373056, y: 29.918886, country: "Burundi" },
  BJ: { x: 9.30769, y: 2.315834, country: "Benin" },
  BM: { x: 32.321384, y: -64.75737, country: "Bermuda" },
  BN: { x: 4.535277, y: 114.727669, country: "Brunei" },
  BO: { x: -16.290154, y: -63.588653, country: "Bolivia" },
  BR: { x: -14.235004, y: -51.92528, country: "Brazil" },
  BS: { x: 25.03428, y: -77.39628, country: "Bahamas" },
  BT: { x: 27.514162, y: 90.433601, country: "Bhutan" },
  BV: { x: -54.423199, y: 3.413194, country: "Bouvet Island" },
  BW: { x: -22.328474, y: 24.684866, country: "Botswana" },
  BY: { x: 53.709807, y: 27.953389, country: "Belarus" },
  BZ: { x: 17.189877, y: -88.49765, country: "Belize" },
  CA: { x: 56.130366, y: -106.346771, country: "Canada" },
  CC: { x: -12.164165, y: 96.870956, country: "Cocos [Keeling] Islands" },
  CD: { x: -4.038333, y: 21.758664, country: "Congo [DRC]" },
  CF: { x: 6.611111, y: 20.939444, country: "Central African Republic" },
  CG: { x: -0.228021, y: 15.827659, country: "Congo [Republic]" },
  CH: { x: 46.818188, y: 8.227512, country: "Switzerland" },
  CI: { x: 7.539989, y: -5.54708, country: "C�te d'Ivoire" },
  CK: { x: -21.236736, y: -159.777671, country: "Cook Islands" },
  CL: { x: -35.675147, y: -71.542969, country: "Chile" },
  CM: { x: 7.369722, y: 12.354722, country: "Cameroon" },
  CN: { x: 35.86166, y: 104.195397, country: "China" },
  CO: { x: 4.570868, y: -74.297333, country: "Colombia" },
  CR: { x: 9.748917, y: -83.753428, country: "Costa Rica" },
  CU: { x: 21.521757, y: -77.781167, country: "Cuba" },
  CV: { x: 16.002082, y: -24.013197, country: "Cape Verde" },
  CX: { x: -10.447525, y: 105.690449, country: "Christmas Island" },
  CY: { x: 35.126413, y: 33.429859, country: "Cyprus" },
  CZ: { x: 49.817492, y: 15.472962, country: "Czech Republic" },
  DE: { x: 51.165691, y: 10.451526, country: "Germany" },
  DJ: { x: 11.825138, y: 42.590275, country: "Djibouti" },
  DK: { x: 56.26392, y: 9.501785, country: "Denmark" },
  DM: { x: 15.414999, y: -61.370976, country: "Dominica" },
  DO: { x: 18.735693, y: -70.162651, country: "Dominican Republic" },
  DZ: { x: 28.033886, y: 1.659626, country: "Algeria" },
  EC: { x: -1.831239, y: -78.183406, country: "Ecuador" },
  EE: { x: 58.595272, y: 25.013607, country: "Estonia" },
  EG: { x: 26.820553, y: 30.802498, country: "Egypt" },
  EH: { x: 24.215527, y: -12.885834, country: "Western Sahara" },
  ER: { x: 15.179384, y: 39.782334, country: "Eritrea" },
  ES: { x: 40.463667, y: -3.74922, country: "Spain" },
  ET: { x: 9.145, y: 40.489673, country: "Ethiopia" },
  FI: { x: 61.92411, y: 25.748151, country: "Finland" },
  FJ: { x: -16.578193, y: 179.414413, country: "Fiji" },
  FK: {
    x: -51.796253,
    y: -59.523613,
    country: "Falkland Islands [Islas Malvinas]",
  },
  FM: { x: 7.425554, y: 150.550812, country: "Micronesia" },
  FO: { x: 61.892635, y: -6.911806, country: "Faroe Islands" },
  FR: { x: 46.227638, y: 2.213749, country: "France" },
  GA: { x: -0.803689, y: 11.609444, country: "Gabon" },
  GB: { x: 55.378051, y: -3.435973, country: "United Kingdom" },
  GD: { x: 12.262776, y: -61.604171, country: "Grenada" },
  GE: { x: 42.315407, y: 43.356892, country: "Georgia" },
  GF: { x: 3.933889, y: -53.125782, country: "French Guiana" },
  GG: { x: 49.465691, y: -2.585278, country: "Guernsey" },
  GH: { x: 7.946527, y: -1.023194, country: "Ghana" },
  GI: { x: 36.137741, y: -5.345374, country: "Gibraltar" },
  GL: { x: 71.706936, y: -42.604303, country: "Greenland" },
  GM: { x: 13.443182, y: -15.310139, country: "Gambia" },
  GN: { x: 9.945587, y: -9.696645, country: "Guinea" },
  GP: { x: 16.995971, y: -62.067641, country: "Guadeloupe" },
  GQ: { x: 1.650801, y: 10.267895, country: "Equatorial Guinea" },
  GR: { x: 39.074208, y: 21.824312, country: "Greece" },
  GS: {
    x: -54.429579,
    y: -36.587909,
    country: "South Georgia and the South Sandwich Islands",
  },
  GT: { x: 15.783471, y: -90.230759, country: "Guatemala" },
  GU: { x: 13.444304, y: 144.793731, country: "Guam" },
  GW: { x: 11.803749, y: -15.180413, country: "Guinea-Bissau" },
  GY: { x: 4.860416, y: -58.93018, country: "Guyana" },
  GZ: { x: 31.354676, y: 34.308825, country: "Gaza Strip" },
  HK: { x: 22.396428, y: 114.109497, country: "Hong Kong" },
  HM: {
    x: -53.08181,
    y: 73.504158,
    country: "Heard Island and McDonald Islands",
  },
  HN: { x: 15.199999, y: -86.241905, country: "Honduras" },
  HR: { x: 45.1, y: 15.2, country: "Croatia" },
  HT: { x: 18.971187, y: -72.285215, country: "Haiti" },
  HU: { x: 47.162494, y: 19.503304, country: "Hungary" },
  ID: { x: -0.789275, y: 113.921327, country: "Indonesia" },
  IE: { x: 53.41291, y: -8.24389, country: "Ireland" },
  IL: { x: 31.046051, y: 34.851612, country: "Israel" },
  IM: { x: 54.236107, y: -4.548056, country: "Isle of Man" },
  IN: { x: 20.593684, y: 78.96288, country: "India" },
  IO: {
    x: -6.343194,
    y: 71.876519,
    country: "British Indian Ocean Territory",
  },
  IQ: { x: 33.223191, y: 43.679291, country: "Iraq" },
  IR: { x: 32.427908, y: 53.688046, country: "Iran" },
  IS: { x: 64.963051, y: -19.020835, country: "Iceland" },
  IT: { x: 41.87194, y: 12.56738, country: "Italy" },
  JE: { x: 49.214439, y: -2.13125, country: "Jersey" },
  JM: { x: 18.109581, y: -77.297508, country: "Jamaica" },
  JO: { x: 30.585164, y: 36.238414, country: "Jordan" },
  JP: { x: 36.204824, y: 138.252924, country: "Japan" },
  KE: { x: -0.023559, y: 37.906193, country: "Kenya" },
  KG: { x: 41.20438, y: 74.766098, country: "Kyrgyzstan" },
  KH: { x: 12.565679, y: 104.990963, country: "Cambodia" },
  KI: { x: -3.370417, y: -168.734039, country: "Kiribati" },
  KM: { x: -11.875001, y: 43.872219, country: "Comoros" },
  KN: { x: 17.357822, y: -62.782998, country: "Saint Kitts and Nevis" },
  KP: { x: 40.339852, y: 127.510093, country: "North Korea" },
  KR: { x: 35.907757, y: 127.766922, country: "South Korea" },
  KW: { x: 29.31166, y: 47.481766, country: "Kuwait" },
  KY: { x: 19.513469, y: -80.566956, country: "Cayman Islands" },
  KZ: { x: 48.019573, y: 66.923684, country: "Kazakhstan" },
  LA: { x: 19.85627, y: 102.495496, country: "Laos" },
  LB: { x: 33.854721, y: 35.862285, country: "Lebanon" },
  LC: { x: 13.909444, y: -60.978893, country: "Saint Lucia" },
  LI: { x: 47.166, y: 9.555373, country: "Liechtenstein" },
  LK: { x: 7.873054, y: 80.771797, country: "Sri Lanka" },
  LR: { x: 6.428055, y: -9.429499, country: "Liberia" },
  LS: { x: -29.609988, y: 28.233608, country: "Lesotho" },
  LT: { x: 55.169438, y: 23.881275, country: "Lithuania" },
  LU: { x: 49.815273, y: 6.129583, country: "Luxembourg" },
  LV: { x: 56.879635, y: 24.603189, country: "Latvia" },
  LY: { x: 26.3351, y: 17.228331, country: "Libya" },
  MA: { x: 31.791702, y: -7.09262, country: "Morocco" },
  MC: { x: 43.750298, y: 7.412841, country: "Monaco" },
  MD: { x: 47.411631, y: 28.369885, country: "Moldova" },
  ME: { x: 42.708678, y: 19.37439, country: "Montenegro" },
  MG: { x: -18.766947, y: 46.869107, country: "Madagascar" },
  MH: { x: 7.131474, y: 171.184478, country: "Marshall Islands" },
  MK: { x: 41.608635, y: 21.745275, country: "Macedonia [FYROM]" },
  ML: { x: 17.570692, y: -3.996166, country: "Mali" },
  MM: { x: 21.913965, y: 95.956223, country: "Myanmar [Burma]" },
  MN: { x: 46.862496, y: 103.846656, country: "Mongolia" },
  MO: { x: 22.198745, y: 113.543873, country: "Macau" },
  MP: { x: 17.33083, y: 145.38469, country: "Northern Mariana Islands" },
  MQ: { x: 14.641528, y: -61.024174, country: "Martinique" },
  MR: { x: 21.00789, y: -10.940835, country: "Mauritania" },
  MS: { x: 16.742498, y: -62.187366, country: "Montserrat" },
  MT: { x: 35.937496, y: 14.375416, country: "Malta" },
  MU: { x: -20.348404, y: 57.552152, country: "Mauritius" },
  MV: { x: 3.202778, y: 73.22068, country: "Maldives" },
  MW: { x: -13.254308, y: 34.301525, country: "Malawi" },
  MX: { x: 23.634501, y: -102.552784, country: "Mexico" },
  MY: { x: 4.210484, y: 101.975766, country: "Malaysia" },
  MZ: { x: -18.665695, y: 35.529562, country: "Mozambique" },
  NA: { x: -22.95764, y: 18.49041, country: "Namibia" },
  NC: { x: -20.904305, y: 165.618042, country: "New Caledonia" },
  NE: { x: 17.607789, y: 8.081666, country: "Niger" },
  NF: { x: -29.040835, y: 167.954712, country: "Norfolk Island" },
  NG: { x: 9.081999, y: 8.675277, country: "Nigeria" },
  NI: { x: 12.865416, y: -85.207229, country: "Nicaragua" },
  NL: { x: 52.132633, y: 5.291266, country: "Netherlands" },
  NO: { x: 60.472024, y: 8.468946, country: "Norway" },
  NP: { x: 28.394857, y: 84.124008, country: "Nepal" },
  NR: { x: -0.522778, y: 166.931503, country: "Nauru" },
  NU: { x: -19.054445, y: -169.867233, country: "Niue" },
  NZ: { x: -40.900557, y: 174.885971, country: "New Zealand" },
  OM: { x: 21.512583, y: 55.923255, country: "Oman" },
  PA: { x: 8.537981, y: -80.782127, country: "Panama" },
  PE: { x: -9.189967, y: -75.015152, country: "Peru" },
  PF: { x: -17.679742, y: -149.406843, country: "French Polynesia" },
  PG: { x: -6.314993, y: 143.95555, country: "Papua New Guinea" },
  PH: { x: 12.879721, y: 121.774017, country: "Philippines" },
  PK: { x: 30.375321, y: 69.345116, country: "Pakistan" },
  PL: { x: 51.919438, y: 19.145136, country: "Poland" },
  PM: { x: 46.941936, y: -56.27111, country: "Saint Pierre and Miquelon" },
  PN: { x: -24.703615, y: -127.439308, country: "Pitcairn Islands" },
  PR: { x: 18.220833, y: -66.590149, country: "Puerto Rico" },
  PS: { x: 31.952162, y: 35.233154, country: "Palestinian Territories" },
  PT: { x: 39.399872, y: -8.224454, country: "Portugal" },
  PW: { x: 7.51498, y: 134.58252, country: "Palau" },
  PY: { x: -23.442503, y: -58.443832, country: "Paraguay" },
  QA: { x: 25.354826, y: 51.183884, country: "Qatar" },
  RE: { x: -21.115141, y: 55.536384, country: "R�union" },
  RO: { x: 45.943161, y: 24.96676, country: "Romania" },
  RS: { x: 44.016521, y: 21.005859, country: "Serbia" },
  RU: { x: 61.52401, y: 105.318756, country: "Russia" },
  RW: { x: -1.940278, y: 29.873888, country: "Rwanda" },
  SA: { x: 23.885942, y: 45.079162, country: "Saudi Arabia" },
  SB: { x: -9.64571, y: 160.156194, country: "Solomon Islands" },
  SC: { x: -4.679574, y: 55.491977, country: "Seychelles" },
  SD: { x: 12.862807, y: 30.217636, country: "Sudan" },
  SE: { x: 60.128161, y: 18.643501, country: "Sweden" },
  SG: { x: 1.352083, y: 103.819836, country: "Singapore" },
  SH: { x: -24.143474, y: -10.030696, country: "Saint Helena" },
  SI: { x: 46.151241, y: 14.995463, country: "Slovenia" },
  SJ: { x: 77.553604, y: 23.670272, country: "Svalbard and Jan Mayen" },
  SK: { x: 48.669026, y: 19.699024, country: "Slovakia" },
  SL: { x: 8.460555, y: -11.779889, country: "Sierra Leone" },
  SM: { x: 43.94236, y: 12.457777, country: "San Marino" },
  SN: { x: 14.497401, y: -14.452362, country: "Senegal" },
  SO: { x: 5.152149, y: 46.199616, country: "Somalia" },
  SR: { x: 3.919305, y: -56.027783, country: "Suriname" },
  ST: { x: 0.18636, y: 6.613081, country: "S�o Tom� and Pr�ncipe" },
  SV: { x: 13.794185, y: -88.89653, country: "El Salvador" },
  SY: { x: 34.802075, y: 38.996815, country: "Syria" },
  SZ: { x: -26.522503, y: 31.465866, country: "Swaziland" },
  TC: { x: 21.694025, y: -71.797928, country: "Turks and Caicos Islands" },
  TD: { x: 15.454166, y: 18.732207, country: "Chad" },
  TF: { x: -49.280366, y: 69.348557, country: "French Southern Territories" },
  TG: { x: 8.619543, y: 0.824782, country: "Togo" },
  TH: { x: 15.870032, y: 100.992541, country: "Thailand" },
  TJ: { x: 38.861034, y: 71.276093, country: "Tajikistan" },
  TK: { x: -8.967363, y: -171.855881, country: "Tokelau" },
  TL: { x: -8.874217, y: 125.727539, country: "Timor-Leste" },
  TM: { x: 38.969719, y: 59.556278, country: "Turkmenistan" },
  TN: { x: 33.886917, y: 9.537499, country: "Tunisia" },
  TO: { x: -21.178986, y: -175.198242, country: "Tonga" },
  TR: { x: 38.963745, y: 35.243322, country: "Turkey" },
  TT: { x: 10.691803, y: -61.222503, country: "Trinidad and Tobago" },
  TV: { x: -7.109535, y: 177.64933, country: "Tuvalu" },
  TW: { x: 23.69781, y: 120.960515, country: "Taiwan" },
  TZ: { x: -6.369028, y: 34.888822, country: "Tanzania" },
  UA: { x: 48.379433, y: 31.16558, country: "Ukraine" },
  UG: { x: 1.373333, y: 32.290275, country: "Uganda" },
  UM: { x: null, y: null, country: "U.S. Minor Outlying Islands" },
  US: { x: 37.09024, y: -95.712891, country: "United States" },
  UY: { x: -32.522779, y: -55.765835, country: "Uruguay" },
  UZ: { x: 41.377491, y: 64.585262, country: "Uzbekistan" },
  VA: { x: 41.902916, y: 12.453389, country: "Vatican City" },
  VC: {
    x: 12.984305,
    y: -61.287228,
    country: "Saint Vincent and the Grenadines",
  },
  VE: { x: 6.42375, y: -66.58973, country: "Venezuela" },
  VG: { x: 18.420695, y: -64.639968, country: "British Virgin Islands" },
  VI: { x: 18.335765, y: -64.896335, country: "U.S. Virgin Islands" },
  VN: { x: 14.058324, y: 108.277199, country: "Vietnam" },
  VU: { x: -15.376706, y: 166.959158, country: "Vanuatu" },
  WF: { x: -13.768752, y: -177.156097, country: "Wallis and Futuna" },
  WS: { x: -13.759029, y: -172.104629, country: "Samoa" },
  XK: { x: 42.602636, y: 20.902977, country: "Kosovo" },
  YE: { x: 15.552727, y: 48.516388, country: "Yemen" },
  YT: { x: -12.8275, y: 45.166244, country: "Mayotte" },
  ZA: { x: -30.559482, y: 22.937506, country: "South Africa" },
  ZM: { x: -13.133897, y: 27.849332, country: "Zambia" },
  ZW: { x: -19.015438, y: 29.154857, country: "Zimbabwe" },
};
export { CountryData };
